import React, { CSSProperties, useState } from "react";
import moment from "moment";
import { PositioningStrategy } from "@popperjs/core";
import {
	cn,
	DataObserveKey,
	FocusableComponent,
	useTranslations,
	VisualComponent,
} from "@siteimprove/fancylib";
import { useLabTranslations } from "../../../translations/translations";
import { ActionBar } from "../action-bar/action-bar";
import { Popover } from "../../overlay/popover/popover";
import { dataObserveKeyDiscriminator } from "../../../utils/shorthands";
import { PeriodType, utcToLocal } from "./presets";
import { PeriodPickerButton } from "./period-picker-button";
import {
	PeriodPickerSelector,
	PeriodPickerSelectorProps,
	PeriodPickerValue,
} from "./period-picker-selector";
import * as scss from "./period-picker.scss";

export { PeriodType };
export type { PeriodPickerValue };

export type PeriodPickerProps = Omit<PeriodPickerSelectorProps, "value" | "onChange"> & {
	/** The value of the component */
	value: PeriodPickerValue | null;
	/** Callback that is called when the value changes */
	onChange: (value: PeriodPickerValue | null) => void;
	/** Whether the component is disabled */
	disabled?: boolean;
	/** Label of the form control */
	"aria-label"?: string;
	/** ID of an an element that describes what the form control is for */
	"aria-describedby"?: string;
	/** ID of an an element that labels this form control */
	"aria-labelledby"?: string;
	/** Add inline style for the button only if necessary */
	periodButtonStyle?: CSSProperties;
	/** Position popover using fixed or absolute */
	strategy?: PositioningStrategy;
} & DataObserveKey &
	VisualComponent &
	FocusableComponent;

export function PeriodPicker(props: PeriodPickerProps): JSX.Element {
	const {
		value,
		onChange,
		disabled,
		condensed,
		className,
		style,
		"data-observe-key": dataObserveKey,
		periodButtonStyle,
		strategy,
		...rest
	} = props;
	const i18nLib = useTranslations();
	const i18nLab = useLabTranslations();

	const initialValue: PeriodPickerValue = value ? parseToLocal(value) : defaultValueFactory();
	const [innerValue, setInnerValue] = useState<PeriodPickerValue>(initialValue);

	return (
		<Popover
			noMaxWidth
			aria-label={props["aria-label"]}
			className={cn(className, "fancy-PeriodPicker")}
			style={style}
			disabled={disabled}
			data-observe-key={dataObserveKey}
			strategy={strategy}
			buttonContent={<PeriodPickerButton value={value} condensed={condensed} />}
			buttonProps={{
				style: periodButtonStyle ?? undefined,
				className: scss.popoverButton,
				"data-observe-key": dataObserveKeyDiscriminator(dataObserveKey, "PeriodPicker", "Button"),
				"aria-describedby": props["aria-describedby"],
				"aria-labelledby": props["aria-labelledby"],
			}}
			popoverContent={(id, firstFocusableRef, { setIsOpen }) => (
				<div id={id} ref={firstFocusableRef} className={scss.popoverContainer}>
					<PeriodPickerSelector
						{...rest}
						data-observe-key={dataObserveKeyDiscriminator(
							dataObserveKey,
							"PeriodPicker",
							"Selector"
						)}
						value={innerValue}
						onChange={setInnerValue}
					/>
					<ActionBar
						primary={{
							children: i18nLib.confirm,
							onClick: () => {
								setIsOpen(false);
								onChange(innerValue);
							},
						}}
						secondaries={[
							{
								children: i18nLab.clear,
								onClick: () => {
									setIsOpen(false);
									onChange(null);
									setInnerValue(initialValue);
								},
							},
						]}
						cancel={{
							children: i18nLib.cancel,
							onClick: () => {
								setIsOpen(false);
								setInnerValue(initialValue);
							},
						}}
					/>
				</div>
			)}
		/>
	);
}

function defaultValueFactory(): PeriodPickerValue {
	return {
		start: moment().startOf("day").toDate(),
		end: moment().endOf("day").toDate(),
		type: PeriodType.Today,
	};
}

function parseToLocal(p: PeriodPickerValue): PeriodPickerValue {
	return {
		...p,
		start: utcToLocal(p.start),
		end: utcToLocal(p.end),
	};
}
